import {
  plyr_default,
  require_plyr_min
} from "./chunk.UW2KYD6R.js";
import {
  LazyLoadingHandler_default
} from "./chunk.ZXFS3F4J.js";
import {
  Spaceable
} from "./chunk.UQO6F5RO.js";
import {
  to
} from "./chunk.YTEMICXC.js";
import {
  Rt
} from "./chunk.LTAMHVKK.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  JBCookie,
  debounce
} from "./chunk.WTC7JQ7D.js";
import {
  D,
  h,
  ke
} from "./chunk.FCKNEYTX.js";
import {
  e,
  n,
  r as r2,
  r2 as r3
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass,
  __toESM
} from "./chunk.I4IFG4JG.js";

// src/components/jb-video/jb-video.ts
var import_plyr = __toESM(require_plyr_min(), 1);

// src/components/jb-video/jb-video.scss?inline
var jb_video_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
.plyr button[data-plyr=play]:not(.plyr__control--overlaid):hover, .plyr:hover .plyr__control--overlaid, .plyr:hover .plyr__poster {
  transform: scale(1.05);
}

/* stylelint-disable */
/* stylelint-enable */
.plyr {
  font-family: var(--theme-font-bold-family, VerlagSSm, Tahoma, sans-serif);
  font-weight: var(--theme-font-bold-weight, 700);
}

.text, .caption, .credit {
  font-family: var(--theme-font-light-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-light-weight, 300);
}

.credit {
  color: rgba(var(--rgb-primary, 20, 30, 85), var(--text-opacity, var(--theme-opacity-70, 0.7)));
  font-size: 0.813rem;
  line-height: 1.25rem;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  --plyr-range-fill-background: #fff;
  --plyr-control-radius: 0;
  --plyr-color-main: transparent;
  --plyr-range-track-height: 2px;
  --plyr-menu-background: #141e55;
  --plyr-menu-color: rgba(255, 255, 255, 0.7);
  --plyr-video-control-color-hover: var(--plyr-menu-color);
  --plyr-menu-radius: 0;
  --plyr-menu-arrow-color: #fff;
  --plyr-menu-item-arrow-color: #fff;
  --plyr-captions-background: rgba(0, 0, 0, 0.5);
  --plyr-captions-text-color: #fff;
  --plyr-font-size-large: 1.188rem;
  display: block;
  height: auto;
}

video {
  object-fit: cover;
}

.plyr--paused.plyr--stopped .gradient {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
  cursor: pointer;
  inset: 0;
  position: absolute;
  z-index: 2;
}

.videowrapper:not(.isbackground).aspect-21by9 {
  aspect-ratio: 21/9;
}
.videowrapper:not(.isbackground).aspect-16by9 {
  aspect-ratio: 16/9;
}
.videowrapper:not(.isbackground).aspect-4by3 {
  aspect-ratio: 4/3;
}
.videowrapper:not(.isbackground).aspect-3by2 {
  aspect-ratio: 3/2;
}
.videowrapper:not(.isbackground).aspect-1by1 {
  aspect-ratio: 1/1;
}
.videowrapper:not(.isbackground).aspect-2by3 {
  aspect-ratio: 2/3;
}
.videowrapper:not(.isbackground).aspect-3by4 {
  aspect-ratio: 3/4;
}
.videowrapper:not(.isbackground).aspect-9by16 {
  aspect-ratio: 9/16;
}
.videowrapper:not(.isbackground).aspect-9by21 {
  aspect-ratio: 9/21;
}

.isbackground {
  inset: 0;
  position: absolute;
}

.mainvideo .background {
  height: auto;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: auto;
}

/* PLYR Styles override */
.plyr {
  height: 100%;
  /* Show poster if enabled */
  /* allow for interaction with suggestions */
  /* Hide title when playing */
  /* Hide button when playing */
  /* Fullscreen button */
  /* Style range input (volume and position) */
  /* Style play / pause button when playing */
}
.plyr--paused.plyr--stopped .plyr__controls {
  display: none;
}
.plyr__video-wrapper {
  z-index: 0;
}
.plyr--full-ui ::-webkit-media-text-track-container {
  display: var(--webkit-text-track-display, none);
}
.plyr__captions {
  font-family: Arial, sans-serif;
  font-weight: 400;
  z-index: 1;
}
.plyr__caption {
  border-radius: 0;
}
.plyr__menu__container {
  border-radius: 0;
}
.plyr__menu__container [role=menu] {
  padding: 6px 0;
}
.plyr__menu__container .plyr__control {
  margin: 0;
  padding: 6px 16px;
  text-transform: unset;
  width: 100%;
}
.plyr__menu__container .plyr__control--forward,
.plyr__menu__container .plyr__control--back {
  border: none;
}
.plyr__menu__container .plyr__control--forward::after,
.plyr__menu__container .plyr__control--back::after {
  background-size: 18px 18px;
  border: none;
  color: white;
  content: "";
  display: inline-block;
  height: 18px;
  width: 18px;
}
.plyr__menu__container .plyr__control--forward {
  padding-right: 32px;
}
.plyr__menu__container .plyr__control--forward::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#fff' d='M7.01 7.72L.66 1.38l.7-.71 7.08 7.07-.71.7-6.62 6.62-.71-.71 6.61-6.62z' fill-rule='evenodd'/%3E%3C/svg%3E");
  right: 0;
}
.plyr__menu__container .plyr__control--back {
  padding: 12px 32px;
}
.plyr__menu__container .plyr__control--back::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.985 7.725l6.358-6.358-.71-.71-7.071 7.07.71.711.003-.003L7.89 15.05l.71-.71-6.615-6.615z' fill-rule='evenodd' fill='%23fff'/%3E%3C/svg%3E%0A");
  left: 8px;
}
.plyr__menu__container .plyr__control--back::before {
  box-shadow: none;
  left: -7px;
  margin-top: 0;
  width: calc(100% + 14px);
}
.plyr__menu__container .plyr__control[role=menuitemradio]:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.plyr__menu__container .plyr__control[role=menuitemradio]::after {
  background: transparent;
  border: 1px solid transparent;
  border-radius: 0;
  height: 8px;
  left: 8px;
  top: 40%;
  width: 14px;
}
.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::after {
  border-bottom-color: #fff;
  border-left-color: #fff;
  transform: translateY(-50%) scale(1) rotate(-45deg);
}
.plyr__menu__container .plyr__control[role=menuitemradio]::before {
  background-color: transparent;
}
.plyr__menu__container .plyr__control[data-plyr=language] .plyr__menu__value {
  display: none;
}
.plyr__control--overlaid {
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 0;
  bottom: 30px;
  height: 50px;
  left: 30px;
  opacity: 1;
  outline: none;
  padding: 14px;
  top: auto;
  transform: none;
  transition: transform 0.7s, height 0.7s, width 0.7s, padding 0.7s;
  width: 50px;
}
.plyr__control--overlaid svg {
  height: 18px;
  left: 0;
  transition: transform 0.7s;
  width: 18px;
}
.plyr__control--overlaid:hover {
  background-color: transparent;
}
.plyr__poster {
  background-size: cover;
  transition: transform 0.7s;
  z-index: -1;
}
.plyr--stopped.plyr__poster-enabled .plyr__poster {
  z-index: 1;
}
.plyr--paused .plyr__controls {
  pointer-events: none;
}
.plyr--paused .plyr__controls__item {
  pointer-events: auto;
}
.plyr:not(.plyr--stopped) {
  z-index: 1;
}
.plyr--paused:not(.plyr--stopped) .plyr__control--overlaid {
  display: none !important;
}
.plyr [data-plyr=fullscreen] {
  transition: transform 0.3s ease-in-out 0s;
}
.plyr [data-plyr=fullscreen] svg {
  height: 40px;
  width: 40px;
}
.plyr [data-plyr=fullscreen]:hover {
  transform: scale(1.2);
}
.plyr__progress__container {
  padding-left: 10px !important;
}
.plyr input[type=range]::-webkit-slider-thumb {
  transform: scale(0.8);
}
.plyr input[type=range]::-moz-range-thumb {
  transform: scale(0.8);
}
.plyr input[type=range]::-ms-thumb {
  transform: scale(0.8);
}
.plyr input[type=range]:hover::-webkit-slider-thumb {
  -webkit-box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.2);
}
.plyr input[type=range]:hover::-moz-range-thumb {
  -moz-box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.2);
}
.plyr input[type=range]:hover::-ms-thumb {
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.2);
}
.plyr button[data-plyr=play]:not(.plyr__control--overlaid) {
  border: 2px solid #fff !important;
  border-radius: 0 !important;
  font-size: 0.75rem !important;
  height: 38px !important;
  left: 5px !important;
  line-height: 2.813rem !important;
  top: auto !important;
  transform: scale(1);
  transition: transform 0.3s ease-in-out 0s;
  width: 38px !important;
}
.plyr button[data-plyr=play]:not(.plyr__control--overlaid):hover {
  background-color: transparent;
}
.plyr--youtube .plyr__video-wrapper {
  padding-bottom: 56.25% !important;
}

.islarge .plyr__control--overlaid {
  height: 70px;
  padding: 24px;
  transform: translate(30px, -30px);
  width: 70px;
}
.islarge .plyr__control--overlaid svg {
  transform: scale(1.3333);
}
.islarge:hover .plyr__control--overlaid {
  transform: scale(1.05) translate(30px, -30px);
}

section .videocontainer {
  position: relative;
}

.caption {
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 30px;
  line-height: 1.875rem;
  color: #141e55;
  margin-inline: auto;
  margin-top: 16px;
  position: static;
  transition: bottom 0.7s, left 0.7s;
  width: 86.64%;
}
@media (min-width: 600px) {
  .caption {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 30px;
    line-height: 1.875rem;
    bottom: 30px;
    color: #fff;
    font-weight: 200;
    left: 120px;
    margin: 16px 20px 0 0;
    max-width: 580px;
    pointer-events: none;
    position: absolute;
    width: auto;
  }
}
.islarge .caption {
  font-size: 28px;
  font-size: 1.75rem;
  line-height: 36px;
  line-height: 2.25rem;
  bottom: 64px;
  left: 155px;
}

.text {
  color: rgba(20, 30, 85, var(--theme-opacity-80, 0.8));
  margin: 10px auto 0;
  width: 86.64%;
  line-height: 26px;
  line-height: 1.625rem;
}
@media (min-width: 600px) {
  .text {
    margin: 16px auto 0;
    width: 100%;
  }
}

.credit {
  color: rgba(20, 30, 85, var(--theme-opacity-70, 0.7));
  margin: 10px auto 0;
  width: 86.64%;
}
@media (min-width: 600px) {
  .credit {
    margin: 5px auto 0;
    width: 100%;
  }
}

jb-button {
  --stroke-color: \$color-main-white;
}

#caption-button {
  appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  display: flex;
}
#caption-button svg {
  height: 18px;
  width: 18px;
}`;

// src/components/jb-video/jb-video.ts
var JbVideo = class extends Spaceable(h) {
  constructor() {
    super(...arguments);
    this.aspect = "16by9";
    this.controls = "true";
    this.preload = "auto";
    this.youtubeCaptionsActive = false;
    this._BREAKPOINT = 600;
    this._captionLanguage = "";
    this._isLarge = false;
    this._observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          this._dispatchVisibleEvent();
          if (this._previewVideo) {
            this._previewVideo.play();
          }
          if (this._playing) {
            this._plyr.play();
          }
        } else {
          if (this._previewVideo) {
            this._previewVideo.pause();
          }
        }
      });
    });
    this._playing = false;
    this._progressPercentage = 0;
    this._videopreload = "auto";
    this._youtubeSrc = "";
  }
  connectedCallback() {
    super.connectedCallback();
    if (this.youtubeCaptionsLanguageMapping) {
      const foundKey = Object.keys(this.youtubeCaptionsLanguageMapping).find(
        (key) => key === JBCookie.getI18nCookieData().languageIso2
      );
      if (foundKey) {
        this._youtubeCaptionLanguage = this.youtubeCaptionsLanguageMapping[foundKey];
      } else {
        this._youtubeCaptionLanguage = "";
      }
    }
    if (this.src.split(".").pop() !== "mp4") {
      this._youtubeSrc = `https://www.youtube-nocookie.com/embed/${this.src}?rel=0&amp;iv_load_policy=3&amp;modestbranding=0&amp;playsinline=1&amp;enablejsapi=1&amp;color=white
      ${this.autoplay ? "&amp;autoplay=1" : ""}
      ${this.youtubeCaptionsActive ? "&amp;cc_load_policy=1" : ""}
      ${this._youtubeCaptionLanguage ? `&amp;cc_lang_pref=${this._youtubeCaptionLanguage}` : ""}`;
    }
    window.addEventListener("resize", debounce(this._windowResized.bind(this), 500));
  }
  firstUpdated() {
    const ua = window.navigator.userAgent;
    if (ua.match(/iPhone/i) && this._youtubeSrc !== "") return;
    this._initPlayer();
    this._initObserver();
    if (this.preload === "auto") {
      this._videopreload = "none";
      LazyLoadingHandler_default.observe(
        this,
        async () => {
          this._videopreload = "auto";
        },
        "approachingViewport"
      );
    } else {
      this._videopreload = this.preload;
    }
  }
  _dispatchPlayEvent() {
    if (this.isBackground || this._progressPercentage > 0 || this._isVideoGif()) return;
    this.dispatchEvent(
      new CustomEvent("jb-tracking", {
        detail: {
          event: "ce_video",
          event_version: "v2",
          component_name: "video-player",
          file_name: this._videoName,
          file_progress: "start",
          file_type: this._videoExtension
        },
        bubbles: true,
        composed: true
      })
    );
  }
  _dispatchProgressEvent(evt) {
    if (this.isBackground || this._isVideoGif()) return;
    const currentTime = evt.detail.plyr.currentTime || 0;
    const currentDuration = evt.detail.plyr.duration;
    const currentPercentage = currentDuration > 0 ? Math.round(currentTime / currentDuration * 10) * 10 : 0;
    if (currentPercentage > this._progressPercentage) {
      this._progressPercentage = currentPercentage;
      this.dispatchEvent(
        new CustomEvent("jb-tracking", {
          detail: {
            event: "ce_video",
            event_version: "v2",
            component_name: "video-player",
            file_name: this._videoName,
            file_progress: this._progressPercentage + "%",
            file_type: this._videoExtension,
            time_elapsed: Math.round(currentDuration / 100 * this._progressPercentage)
          },
          bubbles: true,
          composed: true
        })
      );
    }
  }
  _dispatchVisibleEvent() {
    if (this.isBackground || this._isVideoGif()) return;
    window.setTimeout(() => {
      this.dispatchEvent(
        new CustomEvent("jb-tracking", {
          detail: {
            event: "ce_video",
            event_version: "v2",
            component_name: "video-player",
            file_name: this._videoName,
            file_progress: "visible",
            file_type: this._videoExtension
          },
          bubbles: true,
          composed: true
        })
      );
    }, 0);
  }
  _getContentSpacingProperties() {
    return { horizontal: "full-width-xs", colsMdUp: 10 };
  }
  _initObserver() {
    const player = this._videoPlayer ?? this._youtubePlayer;
    this._observer.observe(player);
  }
  _initPlayer(toggle_captions = false, start_time = 0) {
    this.youtubeCaptionsActive = toggle_captions ? !this.youtubeCaptionsActive : this.youtubeCaptionsActive;
    const settings = [];
    let plyrOptions = {
      iconUrl: this.iconPath,
      fullscreen: {
        iosNative: true,
        enabled: true
      },
      playsinline: true,
      disablePictureInPicture: true,
      controls: ["play", "play-large", "progress", "current-time", "mute", "volume", "fullscreen"],
      i18n: {},
      settings,
      muted: this.muted,
      autoplay: this.autoplay || toggle_captions,
      clickToPlay: this.clickToPlay,
      youtube: {
        cc_load_policy: this.youtubeCaptionsActive ? 1 : 0,
        cc_lang_pref: this._youtubeCaptionLanguage
      }
    };
    if (this.captions) {
      plyrOptions.controls.splice(5, 0, "captions", "settings");
      plyrOptions.settings.push("captions");
    }
    if (this.controls === "false") {
      plyrOptions.controls = [];
    }
    if (this.i18n) {
      plyrOptions = {
        ...plyrOptions,
        i18n: this.i18n
      };
    }
    if (this.isBackground) {
      plyrOptions.controls = [];
      plyrOptions.clickToPlay = false;
      plyrOptions.fullscreen.enabled = false;
      plyrOptions.autoplay = true;
      this._playing = true;
      this.muted = true;
      plyrOptions.muted = true;
      this.loop = true;
    }
    this._plyr = new import_plyr.default(this._videoPlayer, plyrOptions);
    if (this.muted && !this._youtubeSrc) {
      this._videoPlayer.muted = true;
    }
    this._insertGradient(this._plyr.elements.wrapper);
    this._plyr.on("ready", (evt) => {
      this._windowResized();
      if (this.previewVideoSrc) {
        this._insertPreviewVideo(evt.detail.plyr.elements.poster);
      }
      if (this.youtubeCaptionsLanguageMapping) {
        const plyrVolume = this._youtubePlayer?.querySelector(".plyr__volume");
        const captionbutton = document.createElement("button");
        captionbutton.innerHTML = `<svg aria-hidden="true" focusable="false"><use xlink:href="${this.iconPath}#plyr-captions-${this.youtubeCaptionsActive ? "on" : "off"}"></use></svg>`;
        captionbutton.setAttribute("id", "caption-button");
        captionbutton.classList.add("plyr__controls__item");
        captionbutton.addEventListener("click", () => {
          this._toggleYoutubeCaptions();
        });
        plyrVolume?.parentNode?.insertBefore(captionbutton, plyrVolume.nextSibling);
      }
      if (toggle_captions) {
        this._plyr.currentTime = start_time;
        this._plyr.muted = false;
      }
    });
    this._plyr.on("timeupdate", this._dispatchProgressEvent.bind(this));
    this._plyr.on("playing", this._dispatchPlayEvent.bind(this));
    this._plyr.on("playing", () => {
      this._playing = true;
    });
    this._plyr.on("pause", () => {
      this._playing = false;
    });
    this._plyr.on("ended", () => {
      this._playing = false;
    });
  }
  _insertGradient(playerWrapper) {
    const gradientElement = document.createElement("div");
    gradientElement.classList.add("gradient");
    playerWrapper.appendChild(gradientElement);
  }
  _insertPreviewVideo(localPlyr) {
    if (this.previewVideoSrc) {
      this._previewVideo = document.createElement("video");
      this._previewVideo.src = this.previewVideoSrc;
      this._previewVideo.muted = true;
      this._previewVideo.autoplay = true;
      this._previewVideo.loop = true;
      localPlyr?.appendChild(this._previewVideo);
      this._plyr.elements.container?.classList.add("plyr__poster-enabled");
    }
    if (this._previewVideo) {
      this._observer.observe(this._previewVideo);
    }
  }
  _isVideoGif() {
    return this.loop && this.controls === "false" && this.muted;
  }
  _languageChanged(e2) {
    this._captionLanguage = e2.detail.plyr.captions.language;
  }
  _renderCaptionTracks() {
    if (this.captions) {
      return this.captions.map(
        (caption) => ke`<track
            kind="captions"
            .srclang=${caption.srclang}
            .label=${caption.label}
            .src=${caption.src}
          />`
      );
    }
    return "";
  }
  _renderVideo() {
    return ke`
      <div
        class="${Rt({
      islarge: this._isLarge,
      videowrapper: true,
      [`aspect-${this.aspect}`]: this.aspect,
      isbackground: !!this.isBackground
    })}"
      >
        ${this._youtubeSrc ? ke`
              <div class="${Rt({ youtubeplayer: true, background: !!this.isBackground })}">
                <div class="mainvideo">
                  <iframe
                    .src=${this._youtubeSrc}
                    allowfullscreen
                    allowtransparency
                    allow="autoplay"
                  ></iframe>
                </div>
              </div>
            ` : ke`
              <video
                class="${Rt({ mainvideo: true, background: !!this.isBackground })}"
                playsinline
                preload="${this._videopreload}"
                data-poster="${to(this.poster)}"
                ?loop=${this.loop}
                ?autoplay=${this.autoplay}
                ?muted=${this.muted}
                @ready=${this._videoReady}
                @languagechange=${this._languageChanged}
                @webkitbeginfullscreen=${this._webkitBeginFullScreen}
                @webkitendfullscreen=${this._webkitEndFullScreen}
              >
                <source
                  src="${this.src}"
                  type="video/mp4"
                />
                ${this._renderCaptionTracks()}
              </video>
            `}
      </div>
    `;
  }
  _setCaptionActive() {
    const siteLanguage = JBCookie.getI18nCookieData().languageIso2;
    if (this._videoPlayerTracks) {
      this._videoPlayerTracks.forEach((track) => {
        if (track.srclang === siteLanguage) {
          this._plyr.language = siteLanguage;
          this._plyr.toggleCaptions(true);
        }
      });
    }
  }
  _toggleYoutubeCaptions() {
    this._plyr.destroy();
    this._initPlayer(true, this._plyr.currentTime);
  }
  get _videoExtension() {
    return this._youtubeSrc ? "youtube" : this.src.split(".").pop()?.toLocaleLowerCase();
  }
  get _videoName() {
    return this._youtubeSrc ? this._plyr.config.title + " (" + this.src + ")" : this.src.split("/").pop();
  }
  _videoReady() {
    this._setCaptionActive();
  }
  _webkitBeginFullScreen() {
    document.documentElement.style.setProperty("--webkit-text-track-display", "block");
    if (this._captionLanguage) {
      let trackUid = 0;
      if (this._videoPlayerTracks) {
        this._videoPlayerTracks.forEach((track, index) => {
          if (track.srclang === this._captionLanguage) {
            trackUid = index;
          }
        });
        this._videoPlayer.textTracks[trackUid].mode = "showing";
      }
    }
  }
  _webkitEndFullScreen() {
    document.documentElement.style.setProperty("--webkit-text-track-display", "none");
    if (this._captionLanguage) {
      let trackUid = 0;
      if (this._videoPlayerTracks) {
        this._videoPlayerTracks.forEach((track, index) => {
          if (track.srclang === this._captionLanguage) {
            trackUid = index;
          }
        });
        this._videoPlayer.textTracks[trackUid].mode = "disabled";
      }
    }
  }
  _windowResized() {
    this._isLarge = this.offsetWidth >= this._BREAKPOINT;
  }
  render() {
    return this.caption || this.text || this.credit ? ke`
          <section class="${Rt({ islarge: this._isLarge })}">
            <div class="videocontainer">
              ${this._renderVideo()} ${this.caption ? ke`<p class="caption">${this.caption}</p>` : D}
            </div>
            ${this.text ? ke`<p class="text">${this.text}</p>` : D}
            ${this.credit ? ke`<p class="credit">${this.credit}</p>` : D}
          </section>
        ` : this._renderVideo();
  }
};
JbVideo.styles = r([plyr_default, jb_video_default]);
__decorateClass([
  n()
], JbVideo.prototype, "aspect", 2);
__decorateClass([
  n({ type: Boolean })
], JbVideo.prototype, "autoplay", 2);
__decorateClass([
  n()
], JbVideo.prototype, "caption", 2);
__decorateClass([
  n({ type: Array })
], JbVideo.prototype, "captions", 2);
__decorateClass([
  n({ type: Boolean })
], JbVideo.prototype, "clickToPlay", 2);
__decorateClass([
  n({ type: String })
], JbVideo.prototype, "controls", 2);
__decorateClass([
  n()
], JbVideo.prototype, "credit", 2);
__decorateClass([
  n({ type: Object })
], JbVideo.prototype, "i18n", 2);
__decorateClass([
  n()
], JbVideo.prototype, "iconPath", 2);
__decorateClass([
  n({ type: Boolean })
], JbVideo.prototype, "isBackground", 2);
__decorateClass([
  n({ type: Boolean })
], JbVideo.prototype, "loop", 2);
__decorateClass([
  n({ type: Boolean })
], JbVideo.prototype, "muted", 2);
__decorateClass([
  n()
], JbVideo.prototype, "poster", 2);
__decorateClass([
  n()
], JbVideo.prototype, "preload", 2);
__decorateClass([
  n()
], JbVideo.prototype, "previewVideoSrc", 2);
__decorateClass([
  n()
], JbVideo.prototype, "src", 2);
__decorateClass([
  n()
], JbVideo.prototype, "text", 2);
__decorateClass([
  n({ type: Boolean })
], JbVideo.prototype, "youtubeCaptionsActive", 2);
__decorateClass([
  n({ type: Object })
], JbVideo.prototype, "youtubeCaptionsLanguageMapping", 2);
__decorateClass([
  r2()
], JbVideo.prototype, "_isLarge", 2);
__decorateClass([
  r2()
], JbVideo.prototype, "_playing", 2);
__decorateClass([
  r2()
], JbVideo.prototype, "_progressPercentage", 2);
__decorateClass([
  e(".mainvideo")
], JbVideo.prototype, "_videoPlayer", 2);
__decorateClass([
  r3("track")
], JbVideo.prototype, "_videoPlayerTracks", 2);
__decorateClass([
  r2()
], JbVideo.prototype, "_videopreload", 2);
__decorateClass([
  e(".youtubeplayer")
], JbVideo.prototype, "_youtubePlayer", 2);
JbVideo = __decorateClass([
  customElement("jb-video")
], JbVideo);

export {
  JbVideo
};
